<!-- avic项目登录页面 -->
<template>
  <div></div>
</template>

<script>
import store from '@/micro/globalStore'
import { mapState } from 'vuex'

const bgCarousel = [
  {
    imgPath: 'bg00.png',
    delay: 15
  },
  {
    imgPath: 'bg02.png',
    delay: 15
  },
  {
    imgPath: 'bg03.png',
    delay: 15
  },
  {
    imgPath: 'bg04.png',
    delay: 15
  }
]
export default {
  components: {
    // register,
    // qrcLogin,
    // pwdLogin
  },
  data() {
    return {
      // isPanelCon: pwdLogin,
      positionX: 0,
      positionY: 0,
      left: 0,
      top: 0,
      imgPath: ''
    }
  },
  computed: {
    ...mapState('global', {
      mainMenu: state => state.menu
    })
  },
  created() {
    let obj = {}
    window.location.search.slice(1).split('&').map(item => {
      const arr = item.split('=')
      if (arr[0] === 'ticket') {
        obj.ticket = arr[1]
      }
    })
    obj = obj.ticket ? obj : { ticket: '' }

    this.imgPath = this.getImgPath(0)
    this.slideBgImg()
    this.$store.dispatch('global/loginNew', obj).then(res => {
      if (res.code === 200) {
        const global = this.$store.state.global
        store.setGlobalState({
          // loginUserType: '1',
          token: global.token,
          userInfo: global.userInfo
        })
        this.$store.dispatch('global/dyrouters').then(res => {
          this.$store.commit('global/SET_MENU', res.data)
        })
        this.$http('post_author_listConfigUser', { configCode: 'defaultPage' }, true).then(res => {
          if (res.code === 200 && res.data[0] && res.data[0].configValue) {
            const defaultIndex = res.data[0].configValue
            const { mainMenu } = this
            const ssMenuData = mainMenu.filter(item => item && item.name === '首页')
            console.log('ssMenuData')
            console.log(ssMenuData)
            if ((ssMenuData instanceof Array) && ssMenuData.length > 0) {
              const ssMenuItem = ssMenuData[0].children
              history.pushState(null, '/ss', ssMenuItem[defaultIndex] && ssMenuItem[defaultIndex].path ? ssMenuItem[defaultIndex].path : ssMenuItem[0].path)
            } else {
              history.pushState(null, '/ss', '/ss')
            }
          } else {
            history.pushState(null, '/ss', '/ss')
          }
        })
      } else {
        this.openNotificationWithIcon('error', '登录失败', res.message)
      }
    })
  },
  beforeDestroy() {
    this.trim.forEach((t) => {
      clearTimeout(t)
    })
  },
  methods: {
    getImgPath(index) {
      try {
        return require('./image/' + bgCarousel[index].imgPath)
      } catch (e) {
        return false
      }
    },
    slideBgImg() {
      const total = bgCarousel.reduce((total, num) => (total.delay || total) + num.delay)
      let trim = this.trim = []
      const slideBg = () => {
        let trimTotal = 0
        if (trim.length) {
          trim.forEach((item) => {
            clearTimeout(item)
          })
          trim = []
        }
        bgCarousel.forEach((item, index) => {
          trimTotal += item.delay
          const itemTirm = setTimeout(() => {
            this.imgPath = this.getImgPath(index)
          }, trimTotal * 1000)
          trim.push(itemTirm)
        })
        const totalTrim = setTimeout(() => {
          slideBg()
        }, total * 1000)
        trim.push(totalTrim)
      }
      slideBg()
    },
    mousemove(e) {
      var x = e.clientX
      var y = e.clientY
      if (this.positionX === 0 && this.positionY === 0) {
        this.positionX = x
        this.positionY = y
      }
      if (x > this.positionX && y < this.positionY) {
        this.left = -10
        this.top = -11
      } else if (x > this.positionX && y > this.positionY) {
        this.left = -20
        this.top = -12
      } else if (x < this.positionX && y < this.positionY) {
        this.left = -10
        this.top = -5
      } else if (x < this.positionX && y > this.positionY) {
        this.left = -20
        this.top = -20
      }
      this.positionX = x
      this.positionY = y
    }
  }
}
</script>

<style lang="less" scoped>
  .login {
    position: relative;
    width: 100%;
    min-width: 1200px;
    height: 100%;
    overflow: hidden;
  }
  .login .logo {
    position: absolute;
    top: 30px;
    left: 50px;
  }
  .login .login-type {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  .login .login-panel {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    width: 457px;
    height: 606px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
    box-sizing: content-box;
  }
  .login .login-bg {
    position: absolute;
    -webkit-user-select: none;
    cursor: default;
    pointer-events: none;
  }
  .login .bg-d {
    position: absolute;
    left: -10px;
    top: -10px;
    width: 120%;
    height: 120%;
    transition: all 1s;
  }
  .login .bg-t {
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .login .sys-title {
    position: absolute;
    top: 30%;
    left: 150px;
    text-shadow: #173a7c 1px 1px 2px;
  }
  .login .sys-title .sys-name,
  .login .sys-title .sys-enname  {
    font-size: 55px;
    color: #FFFFFF;
    line-height: 79px;
  }
  .login .sys-title .sys-enname {
    width: 400px;
    text-transform: uppercase;
    line-height: 60px;
    margin: 20px 0;
  }
  .login .sys-title .sys-cn {
    font-size: 24px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.59);
    line-height: 33px;
    letter-spacing: 1px;
  }
  /* 切换动画 */
  .switch-fade-enter{
    opacity: 0;
    transform: translateX(-100%);
  }
  .switch-fade-leave-to{
    opacity: 0;
    transform: translateX(100%);
    // 解决页面从上往下位移问题
    position: absolute;
  }
  .switch-fade-enter-active,.switch-fade-leave-active{
    transition: all 0.35s ease;
  }
</style>
